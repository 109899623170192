import { Radio as SuRadio } from "@stayunique/stayunique-ui";
import { Field } from "react-final-form";

type RadioType = {
    /**
     * Label of the field
     */
    label?: string;
    /**
     * Name of the field
     */
    name: string;
    /**
     * Options
     */
    options: { value: string; label: string }[];
};

/** Radio component */
const Radio = ({ name, options, label }: RadioType) => {
    return <Field name={name}>{({ input }) => <SuRadio label={label} options={options} onChange={input.onChange} value={input.value} />}</Field>;
};

export default Radio;
