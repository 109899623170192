import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Form } from "react-final-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// components
import Navigation from "./Layout/Navigation";

//fields
import AsyncSelect from "./fields/AsyncSelect";
import Checkbox from "./fields/Checkbox";
import Uploader from "./fields/Uploader";
import Select from "./fields/Select";
import TextField from "./fields/TextField";
import Radio from "./fields/Radio";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1
    },
    content: {
        flexGrow: 1,
        margin: theme.spacing()
    }
}));

function Home() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (values: any) => {
        try {
            console.log(values);

            enqueueSnackbar(t("misc.success"), {
                variant: "success",
                preventDuplicate: true
            });
        } catch (e) {
            enqueueSnackbar(t("misc.error"), {
                variant: "error",
                preventDuplicate: true
            });
        }
    };

    return (
        <div className={classes.root}>
            <Navigation />

            <main className={classes.content}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        select: "1",
                        text: "text",
                        checkbox: true,
                        radio: "1"
                    }}
                    render={({ handleSubmit, values }: any) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                {JSON.stringify(values)}

                                <Select
                                    label="select"
                                    name="select"
                                    options={[
                                        { value: "1", label: "Option 1" },
                                        { value: "2", label: "Option 2" }
                                    ]}
                                    isClearable
                                />

                                <Radio
                                    label="radio"
                                    name="radio"
                                    options={[
                                        { value: "1", label: "Option 1" },
                                        { value: "2", label: "Option 2" }
                                    ]}
                                />

                                <br />

                                <Checkbox name="checkbox" label="checkbox" />

                                <TextField label="text" name="text" fullWidth />

                                <AsyncSelect label="asyncSelect" name="asyncSelect" placeholderValue={{ value: "62201", label: "Josh Fradley - 20903" }} isClearable />

                                <Uploader name="uploader" uploadType="test" destination="test" />

                                <Button color="primary" variant="contained" onClick={handleSubmit}>
                                    {t("misc.add")}
                                </Button>
                            </form>
                        );
                    }}
                />
            </main>
        </div>
    );
}

export default Home;
