import AppsIcon from "@material-ui/icons/Apps";

const configuration = {
    appName: "App Name",
    appIcon: <AppsIcon />,
    primary: "#000000",
    secondary: "#000000"
};

export default configuration;
